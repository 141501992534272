// import $ from 'jquery';
import Swiper from 'swiper/bundle';
// import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import VanillaTilt from 'vanilla-tilt';
import remodal from 'remodal';
// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);

const isSmartPhone = () => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
    return true;
  } else {
    return false;
  }
};



/**
 * @desc スクロールであるポイントを超えたらクラスを付与
 * @param {*} targetEl  
 * @param {*} togglePointEl 
 * @param {*} toggleClass 
 */
const scrollInToggleClass = (targetEl, togglePointEl, toggleClass) => {

  if (togglePointEl) {
    const observer1 = new IntersectionObserver((entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          targetEl.classList.remove(toggleClass);
        } else {
          targetEl.classList.add(toggleClass);
        }
      }
    });
    observer1.observe(togglePointEl);
  } else {
    if (targetEl) {
      targetEl.classList.add(toggleClass);
    }
  }
};


// scrollInToggleClass(header, document.querySelector(".js-hero"), "is-active")
// scrollInToggleClass(document.querySelector(".js-header-fresh"), document.querySelector(".js-hero"), "is-active")

/**
 * @desc 一文字づつ文字をラップする
 * @param {*} target 
 * @returns 一文字つづspanでラップしたテキスト
 */
const spanWrapText = (target) => {
  const nodes = [...target.childNodes];
  let returnText = '';

  for (const node of nodes) {
    if (node.nodeType == 3) {
      const text = node.textContent.replace(/\r?\n/g, '');
      const splitText = text.split('');
      for (const char of splitText) {
        returnText += `<span>${char}</span>`;
      }
    } else {
      returnText += node.outerHTML;
    }
  }
  return returnText;
};

/* */

const hero = document.querySelector(".js-hero")
if (hero) {
  const heroTl = gsap.timeline();
  heroTl.from(hero.querySelector('.hero__mv img'), {
    scale: 1.2,
    duration: 1.75
  });
  gsap.fromTo(header, {
    y: "-100%",
    opacity: 0
  }, {
    duration: 1.5,
    opacity: 1,
    y: 0
  });
  gsap.fromTo(hero.querySelector('.hero__main picture'), {
    opacity: 0,
    y: 50,
  }, {
    duration: 1.7,
    y: 0,
    opacity: 1,
  });
}


const fadeInEls = gsap.utils.toArray('.js-fadein-effect1');
if (fadeInEls.length != 0) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 30,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 0.55,
      scrollTrigger: {
        trigger: box,
        start: 'center bottom',
        // end: 'bottom top',
      }
    });
  });
}
const fadeInEls2 = gsap.utils.toArray('.js-fadein-effect2');
if (fadeInEls2.length != 0) {
  fadeInEls2.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 40,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: .55,
      scrollTrigger: {
        trigger: box,
        start: 'center bottom',
        // end: 'bottom top',
      }
    });
  });
}
const scaleEffect = gsap.utils.toArray('.js-scale-effect1');
if (scaleEffect.length != 0) {
  scaleEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 30,
      scale: .55,
      opacity: 0,
    }, {
      y: 0,
      scale: 1,
      opacity: 1,
      duration: .55,
      scrollTrigger: {
        trigger: box,
        start: 'center bottom',
                // end: 'bottom top',
      }
    });
  });
}
const rollEffect = gsap.utils.toArray('.js-x-effect1');
if (rollEffect.length != 0) {
  rollEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      x: "25%",
    }, {
      x: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'center bottom',
      }
    });
  });
}
const rollEffect2 = gsap.utils.toArray('.js-x-effect2');
if (rollEffect2.length != 0) {
  rollEffect2.forEach((box, index) => {
    gsap.fromTo(box, {
      x: "5%",
      opacity: 0,
    }, {
      x: 0,
      opacity: 1,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'center bottom',
      }
    });
  });
}
const yEffect = gsap.utils.toArray('.js-y-effect1');
if (yEffect.length != 0) {
  yEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      y: "25%",
      opacity:0,
    }, {
      y: 0,
      opacity:1,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'center bottom',
      }
    });
  });
}
const yEffect2 = gsap.utils.toArray('.js-y-effect2');
if (yEffect2.length != 0) {
  yEffect2.forEach((box, index) => {
    gsap.fromTo(box, {
      y: "-25%",
    }, {
      y: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'center bottom',
      }
    });
  });
}
const staggerEffect1 = gsap.utils.toArray('.js-stagger-effect1');
if (staggerEffect1.length != 0) {
  // staggerEffect1.forEach((box, index) => {
  gsap.fromTo(staggerEffect1, {
    y: 50,
    opacity: 0,
    scale:0,
  }, {
    y: 0,
    scale:1,
    opacity: 1,
    stagger: 0.5,
    duration: 1,
    scrollTrigger: {
      trigger: staggerEffect1,
        start: 'center bottom',
    }
  });
  // });
}
const staggerEffect2 = gsap.utils.toArray('.js-stagger-effect2');
if (staggerEffect2.length != 0) {
  // staggerEffect2.forEach((box, index) => {
  gsap.fromTo(staggerEffect2, {
    x: 70,
    opacity: 0,
  }, {
    x: 0,
    opacity: 1,
    stagger: 0.25,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect2,
        start: 'center bottom',
    }
  });
  // });
}
const staggerEffect3 = gsap.utils.toArray('.js-stagger-effect3');
if (staggerEffect3.length != 0) {
  gsap.fromTo(staggerEffect3, {
    y: -30,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    stagger: .5,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect3,
        start: 'center bottom',
    }
  });
}
const staggerEffect4 = gsap.utils.toArray('.js-stagger-effect4');
if (staggerEffect4.length != 0) {
  gsap.fromTo(staggerEffect4, {
    y: -150,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    stagger: .25,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect4,
        start: 'center bottom',
    }
  });
}

// tl.from(spans, {
//   opacity: 0,
//   duration: 0.01,
//   stagger: 0.08,
// });
const circles = gsap.utils.toArray('.js-circle');
if (circles.length != 0) {
  for (circle of circles) {
    gsap.to(circle, gsap.utils.random(2, 3), {
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
      x: gsap.utils.random(10, 20),
      y: gsap.utils.random(10, 30),
    })
  }
}





// const scrollDownToggleClass = new ScrollDownToggleClass(document.getElementById('header'));
// window.addEventListener('scroll', () => {
//   scrollDownToggleClass.toggleClass()
// });



/****/
//

// mv animation

// $(function(){

//   $('.js-toggle').on('click', function(){
//     $(this).toggleClass('is-active')
//     $(this).next().slideToggle()
//   })
// })

// (function(){
//   const heroMv = document.querySelector('.hero__mv')
//   const count = 400; 
//   for (let i = 0 ; i < 400; i++) {
//     let block = document.createElement('div')
//     block.classList.add('box')
//     heroMv.appendChild(block)
//   }
//   const boxs = gsap.utils.toArray('.box');
//   if (boxs) {
//     gsap.from(boxs, {
//       // delay.05
//       top: 80,
//       left: 80,
//       stagger: .005,
//       // scale : 150
//       // y: 10,
//       // opacity: 1,
//     })
//   }
// })()



// gsap.set(document.querySelector(".js-mov"), {perspective:200});
// gsap.from(document.querySelector(".js-mov"), .05, {css:{autoAlpha:0}}, {css:{autoAlpha:1}, immediateRender:true});
// gsap.fromTo(document.querySelector(".js-mov"), {
//   transform: "rotate3d(-40, 10, 5, 75deg)"
// }, {
//   transform: "none",
//   duration: 3
// })
// gsap.from(document.querySelector(".js-mov"), {
//   rotateZ: "80",
//   skewY: -40,
//   // skewX: 40,
//   x: -10,
//   // y: -90,
//   scale: 1.05,
//   duration: 1.2
// });
// gsap.from(document.querySelector(".js-mov1"), {
//   rotate: "-305",
//   scale: .5,
//   duration: 1.2
// });
// gsap.from(document.querySelector(".js-mov2"), {
//   rotate: "-105",
//   skewY: 30,
//   x: 20,
//   skewX: 20,
//   scale: .5,
//   duration: 1.2
// });

const scrabsscale1 = gsap.utils.toArray('.js-scale-scrab1');
if (scrabsscale1.length != 0) {
  scrabsscale1.forEach((box, index) => {
    gsap.from(box, {
      duration: 1.4,
      autoAlpha: 0,
      x: 120,
      scale: 0.3,
      scrollTrigger: {
        trigger: box,
        // markers: true,
        // scrub: true,
        scrub: 1, //1秒間アニメーションする
      }
    })
  })
}
const scrabs1 = gsap.utils.toArray('.js-x-scrab1');
if (scrabs1.length != 0) {
  scrabs1.forEach((box, index) => {
    gsap.from(box, {
      duration: 1.4,
      x: -120,
      scrollTrigger: {
        trigger: box,
        // markers: true,
        // scrub: true,
        scrub: 1, //1秒間アニメーションする
      }
    })
  })
}
const scrabs2 = gsap.utils.toArray('.js-x-scrab2');
if (scrabs2.length != 0) {
  scrabs2.forEach((box, index) => {
    gsap.from(box, {
      duration: 1.4,
      x: "6rem",
      scrollTrigger: {
        trigger: box,
        // markers: true,
        // scrub: true,
        scrub: 1, //1秒間アニメーションする
      }
    })
  })
}

/** */

let spSwiper1 = null;
let spSwiper1El = document.querySelector('.js-sp-swiper1');
let spSwiper2 = null;
let spSwiper2El = document.querySelector('.js-sp-swiper2');
let spSwiper3 = null;
let spSwiper3El = document.querySelector('.js-sp-swiper3');

let swiperbool = false;
const initSwiperSp = () => {
  if (spSwiper1El.length != 0 && spSwiper1El.querySelectorAll('.swiper-slide').length != 0) {
    spSwiper1 = new Swiper(spSwiper1El, {
      centeredSlides: true,
      slidesPerView: 1,
      loop: false,
      speed: 1000,
      spaceBetween: 15,
      loopedSlides: spSwiper1El.querySelectorAll('.swiper-slide').length,
      navigation: {
        nextEl: '.js-sp-swiper1-next',
        prevEl: '.js-sp-swiper1-prev',
      },
      pagination: {
        clickable: true,
        el: spSwiper1El.querySelector('.swiper-pagination')
      },
    });
    spSwiper1.on('slideChange', function () {
      const scrollhint = spSwiper1El.querySelector('.scroll-hint-icon-wrap')
      if (scrollhint) {
        const triggerEvent = new Event('scroll')
        spSwiper1El.dispatchEvent(triggerEvent)
      }
    });

  }

  if (spSwiper2El.querySelectorAll('.swiper-slide').length != 0) {
    spSwiper2 = new Swiper(spSwiper2El, {
      centeredSlides: true,
      slidesPerView: 'auto',
      loop: true,
      speed: 1000,
      loopedSlides: spSwiper2El.querySelectorAll('.swiper-slide').length,
    });
    spSwiper2.on('slideChange', function () {
      const scrollhint = spSwiper2El.querySelector('.scroll-hint-icon-wrap')
      if (scrollhint) {
        const triggerEvent = new Event('scroll')
        spSwiper2El.dispatchEvent(triggerEvent)
      }
    });
  }

  if (spSwiper3El.querySelectorAll('.swiper-slide').length != 0) {
    spSwiper3 = new Swiper(spSwiper3El, {
      centeredSlides: true,
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      loopedSlides: spSwiper3El.querySelectorAll('.swiper-slide').length,
      navigation: {
        nextEl: '.js-sp-swiper3-next',
        prevEl: '.js-sp-swiper3-prev',
      },
    });
  }
};

let timeoutID = 0;
let delay = 500;
$(window).on('resize', function () {
  clearTimeout(timeoutID);
  timeoutID = setTimeout(() => {
    // メディアクエリにマッチしたらスライダーを初期化
    if (isSmartPhone() && !swiperbool) {
      swiperbool = true;
      initSwiperSp();
    } else if (!isSmartPhone() && swiperbool) {
      swiperbool = false;
      // メディアクエリにマッチせず、スライダーが初期化済みだったらスライダーを解除
      if (spSwiper1) {
        spSwiper1.destroy(false, true);
      }
      if (spSwiper2) {
        spSwiper2.destroy(false, true);
      }
      if (spSwiper3) {
        spSwiper3.destroy(false, true);
      }
    }
  }, delay);
});

window.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector('.js-sp-swiper1')) {
    if (isSmartPhone()) {
      initSwiperSp();
      swiperbool = true;
    } else {
      swiperbool = false;
    }
  }

  const hero = document.querySelector(".js-hero-slider")
  if (hero) {
    let apconfig = isSmartPhone() ? false : {
      delay: 4000, // 4秒後に次のスライドへ
      disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
    };
    const heroSlider = new Swiper(hero, {
      loop: true,
      loopedSlides: hero.querySelectorAll('.swiper-slide').length,
      speed: 1000,
      spaceBetween: 30,
      slidesPerView: 'auto',
      centeredSlides: true,
      autoplay: apconfig,
      pagination: {
        el: ".hero-swiper-pagination",
        type: "progressbar",
        // clickable: true,
      },
      navigation: {
        nextEl: ".js-hero-slider-next.swiper-button-next",
        prevEl: ".js-hero-slider-prev.swiper-button-prev"
      },
      breakpoints: {
        768: {
          slidesPerView: 'auto',
        }
      }
    });
    heroSlider.on('slideChange', function () {
      const scrollhint = hero.querySelector('.scroll-hint-icon-wrap')
      if (scrollhint) {
        const triggerEvent = new Event('scroll')
        hero.dispatchEvent(triggerEvent)
      }
    });
  }

});

